<template>
  <div class="integral-rules">
    <HeadNav/>
    <div class="content" :class="{'sign-rule': isDaDiSign}">
      <header :class="{'sign-title': isDaDiSign}">{{title}}</header>
      <article v-html="contents">{{contents}}</article>
      <article v-if="isDaDiSign">
        <img src="../../../assets/asign_rules.png" alt="">
      </article>
    </div>
  </div>
</template>

<script>
import HeadNav from "@/components/common/HeadNav";
import {getTagArticleDetail} from "@/services/my";
import { tripartite } from "@/utils/custom";

export default {
  name: "IntegralRules",
  data() {
    return {
      article_tag: 'integral_rule',
      contents: '',
      title:'',
      isDaDiSign: ''  //  是否是大地保险签到
    };
  },
  components: {
    HeadNav
  },
  methods: {
    getParams() {
      this.article_tag = this.$route.query.article_tag ? this.$route.query.article_tag : 'integral_rule';
      if (this.article_tag)
        this.getTagArticleDetail()

    },
    async getTagArticleDetail() {
      try {
        const res = await getTagArticleDetail(this.article_tag);
        if (res.code === 0) {
          const data = res.data;
          console.log(data);
          // this.title = data.title;
          // this.contents = data.contents
          this.isDaDiSign ? '' : this.contents = data.contents
          this.isDaDiSign ? '' : this.title = data.title
        }
      } catch (e) {
        console.log(e);
      }
    },
    //  是否是大地保险签到商城
    isDaDiShop() {
      if (tripartite('VUE_APP_DADIBX')) {
        this.isDaDiSign = true;
        this.title = '用户积分规则'
      }
    }
  },
  mounted() {
    this.getParams()
    this.isDaDiShop()
  }
}
</script>

<style scoped lang="less">
@import "../../../common/less/variable.less";
@import "../../../common/less/mixin.less";

.integral-rules {
  background: @color-white;
  height: 100vh;

  .content {
    padding: 0.54rem 0.24rem;

    header {
      .sc(0.52rem, #333);
      margin-bottom: 0.46rem;
    }

    article {
      .sc(0.3rem, #333);
      line-height: 0.42rem;

      img {
        width: 100%
        // .wh(100%, 5.88rem); 
      }
    }
  }
  .sign-rule {
    padding: 0.54rem 0;

    .sign-title {
      padding: 0 0.24rem
    }
  }
}
</style>
