var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "integral-rules" },
    [
      _c("HeadNav"),
      _c(
        "div",
        { staticClass: "content", class: { "sign-rule": _vm.isDaDiSign } },
        [
          _c("header", { class: { "sign-title": _vm.isDaDiSign } }, [
            _vm._v(_vm._s(_vm.title)),
          ]),
          _c("article", { domProps: { innerHTML: _vm._s(_vm.contents) } }, [
            _vm._v(_vm._s(_vm.contents)),
          ]),
          _vm.isDaDiSign
            ? _c("article", [
                _c("img", {
                  attrs: {
                    src: require("../../../assets/asign_rules.png"),
                    alt: "",
                  },
                }),
              ])
            : _vm._e(),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }